<template>
  <b-card title="Admin Dashboard" class="m-2">
    <b-card header="Users">
      <b-form inline>
        <b-input-group class="mb-2">
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Search"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''" class="mr-2"
              >Clear</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <!-- <b-button
          :to="{ name: 'AdminUserEdit' }"
          variant="primary"
          class="mb-2 mr-2"
          >Create
        </b-button> -->
        <b-button @click="exportUsers()" variant="primary" class="mb-2"
          >Export
        </b-button>
      </b-form>

      <b-table
        hover
        small
        outlined
        stacked="sm"
        :items="users"
        :busy="loading"
        :fields="fields"
        :filter="filter"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template v-slot:cell(email)="row">
          <router-link :to="{ name: 'AdminUser', params: { id: row.item._id } }">{{ row.item.email }}</router-link>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button @click="deleteUserConfirm(row.item)" class="mr-1">
            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
          </b-button>
          <!-- <b-button
            :to="{ name: 'AdminUserEdit', params: { id: row.item._id } }"
          >
            <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
          </b-button> -->
        </template>
        <template v-slot:table-busy>
          <div class="text-center my-5">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="usersTotal"
        :per-page="perPage"
        align="center"
      ></b-pagination>
    </b-card>
  </b-card>
</template>

<script>
import API from "@/api";
import moment from "moment";
export default {
  name: "Admin",
  data() {
    return {
      loading: true,
      filter: "",
      fields: [
        { key: "name", sortable: true },
        { key: "email", sortable: true },
        { key: "numberOfSessions", sortable: true },
        { key: "lastSessionDateTime", sortable: true },
        { key: "lastLogin", sortable: true },
        { key: "actions", sortable: false },
      ],
      currentPage: 1,
      perPage: 10,
      users: [],
    };
  },
  computed: {
    usersTotal() {
      return this.users.length;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    deleteUserConfirm(user) {
      let message = "Are you sure you want to delete user " + user._id + "?";

      this.$bvModal.msgBoxConfirm(message).then(async (value) => {
        if (value) {
          this.deleteUser(user);
        } else {
          // Delete cancelled.
        }
      });
    },
    async deleteUser(user) {
      this.loading = true;

      let filter = {
        _id: user._id,
      };
      let result = await API.delete("users", filter);
      console.log(result);

      this.users = [];
      this.fetchData();
    },
    async fetchData() {
      const realmApp = API.realmApp;

      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("users");
      const userResults = await mongoCollection.find();

      const mongoSessionsCollection = mongo.db("eunoe").collection("sessions");
      const sessionResults = await mongoSessionsCollection.find({}, {
        sort: { startDateTime: -1 },
      });

      userResults.forEach(userResult => {
        const userSessions = sessionResults.filter(r => r.userId === userResult._id.toString());

        if (userSessions[0]) {
          userResult.lastSessionDateTime = moment(userSessions[0].startDateTime).format(
            "YYYY-MM-DD hh:mm a"
          );
        }
        userResult.numberOfSessions = userSessions.length;

        if (userResult.lastLogin) {
          userResult.lastLogin = moment(userResult.lastLogin).format("YYYY-MM-DD hh:mm a");
        }
      });

      this.users = userResults;
      this.loading = false;
    },
    exportUsers() {
      const items = this.users.filter(user => (
        user.name && user.name.toLowerCase().includes(this.filter.toLowerCase())) ||
        user.email.toLowerCase().includes(this.filter.toLowerCase()));

      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);

      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );

      csv.unshift(header.join(","));
      csv = csv.join("\r\n");

      console.log(csv);

      // Save CSV

      const dataURL = "data:text/csv;charset=utf-8," + encodeURI(csv);

      const anchor = document.createElement("a");
      anchor.href = dataURL;
      anchor.download = "users.csv";
      anchor.click();
    },
  },
};
</script>